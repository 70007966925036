import React from 'react';
import { NAVIGATION } from '../constants';
import { HashLink } from 'react-router-hash-link';

export const Navigation = () => {
  const renderMenu = () =>
    NAVIGATION.ITEMS.map((item) => (
      <li key={item.LABEL}>
        <HashLink to={item.HREF} className="page-scroll">
          {item.LABEL}
        </HashLink>
      </li>
    ));

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <HashLink className="navbar-brand page-scroll" to="/#top">
            <img src={NAVIGATION.LOGO} className="w-100" alt={NAVIGATION.ALT} />
          </HashLink>
        </div>
        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">{renderMenu()}</ul>
        </div>
      </div>
    </nav>
  );
};
