import React, { useEffect } from 'react';
import { SERVICES } from '../constants';
import SEO from '../components/seo';

export const Services = () => {
  const renderFirstServices = () =>
    SERVICES.BUY.map((service) => (
      <div key={service.TITLE}>
        <h3>{service.TITLE}</h3>
        <ul>
          {service.LINKS.map((link) => (
            <li key={link}>
              <a href={link} target="_blank" rel="noreferrer">
                {link}
              </a>
            </li>
          ))}
        </ul>
      </div>
    ));

  const renderSecondServices = () =>
    SERVICES.OPTIONS.map((option) => (
      <div key={option.TITLE}>
        <h3>{option.TITLE}</h3>
        <p>{option.TEXT}</p>
      </div>
    ));

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <SEO
        tile="Services Page"
        description="You can see the list of available services by PremiumAutoImport"
      />
      <div id="services" className="text-left">
        <div className="container">
          <div className="section-title">
            <h2 className="services-title">{SERVICES.TITLE}</h2>
            {renderFirstServices()}
            {renderSecondServices()}
          </div>
        </div>
      </div>
    </>
  );
};
