import React from 'react';
import { BUY } from '../constants';

export const Buy = () => {
  const renderCards = () =>
    BUY.CARDS.map((card) => (
      <div key={card.TITLE} className="col-md-12 col-lg-6">
        <i className={card.ICON}></i>
        <h3>{card.TITLE}</h3>
        <p>{card.TEXT}</p>
      </div>
    ));

  return (
    <div id="buy" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{BUY.TITLE}</h2>
        </div>
        <div className="row">{renderCards()}</div>
      </div>
    </div>
  );
};
