import React from 'react';
import { useState } from 'react';
import emailjs from 'emailjs-com';
import { CONTACT } from '../constants';

export const Contact = () => {
  const initialState = {
    name: '',
    email: '',
    message: ''
  };

  const [formValues, setFormValues] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
  };
  console.log(formValues, 'formValues');
  const clearState = () => setFormValues({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(
        () => {
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const renderSocialMedia = () =>
    CONTACT.SOCIAL_MEDIA.map((media) => (
      <li key={media.TITLE}>
        <a target="_blank" rel="noreferrer" href={media.HREF}>
          <i className={`fa fa-${media.TITLE}`}></i>
        </a>
      </li>
    ));

  return (
    <div>
      <div id="contact">
        <div className="container row mx-auto justify-content-between">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>{CONTACT.TITLE}</h2>
                <p>{CONTACT.DESCRIPTION}</p>
                <form name="sentMessage" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control border"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Message"
                      required
                      onChange={handleChange}></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div id="success"></div>
                  <button type="submit" className="btn btn-custom btn-lg">
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1">
            <div className="contact-item section-title">
              <h2>{CONTACT.SUBTITLE}</h2>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> {CONTACT.ADDRESS.LABEL}
                </span>
                <a target="_blank" rel="noreferrer" href={CONTACT.ADDRESS.LOCATION}>
                  <span>{CONTACT.ADDRESS.VALUE}</span>
                </a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> {CONTACT.PHONE.LABEL}
                </span>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`tel:${CONTACT.PHONE.VALUE_1}`}
                  className="contact-hyperlink">
                  {CONTACT.PHONE.VALUE_1}
                </a>
                <div>{`    `}</div>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`tel:${CONTACT.PHONE.VALUE_2}`}
                  className="contact-hyperlink">
                  {CONTACT.PHONE.VALUE_2}
                </a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> {CONTACT.EMAIL.LABEL}
                </span>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`mailto:${CONTACT.EMAIL.VALUE}`}
                  className="contact-hyperlink">
                  {CONTACT.EMAIL.VALUE}
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  {renderSocialMedia()}
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://invite.viber.com/?g2=AQBgDWkXChIzmFLKfmdjSVo5BYEEI82TwnBEi%2FCVF9sAj6bHQcmw0ZmeT4XEfvx6">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M444 49.9C431.3 38.2 379.9 .9 265.3 .4c0 0-135.1-8.1-200.9 52.3C27.8 89.3 14.9 143 13.5 209.5c-1.4 66.5-3.1 191.1 117 224.9h.1l-.1 51.6s-.8 20.9 13 25.1c16.6 5.2 26.4-10.7 42.3-27.8 8.7-9.4 20.7-23.2 29.8-33.7 82.2 6.9 145.3-8.9 152.5-11.2 16.6-5.4 110.5-17.4 125.7-142 15.8-128.6-7.6-209.8-49.8-246.5zM457.9 287c-12.9 104-89 110.6-103 115.1-6 1.9-61.5 15.7-131.2 11.2 0 0-52 62.7-68.2 79-5.3 5.3-11.1 4.8-11-5.7 0-6.9 .4-85.7 .4-85.7-.1 0-.1 0 0 0-101.8-28.2-95.8-134.3-94.7-189.8 1.1-55.5 11.6-101 42.6-131.6 55.7-50.5 170.4-43 170.4-43 96.9 .4 143.3 29.6 154.1 39.4 35.7 30.6 53.9 103.8 40.6 211.1zm-139-80.8c.4 8.6-12.5 9.2-12.9 .6-1.1-22-11.4-32.7-32.6-33.9-8.6-.5-7.8-13.4 .7-12.9 27.9 1.5 43.4 17.5 44.8 46.2zm20.3 11.3c1-42.4-25.5-75.6-75.8-79.3-8.5-.6-7.6-13.5 .9-12.9 58 4.2 88.9 44.1 87.8 92.5-.1 8.6-13.1 8.2-12.9-.3zm47 13.4c.1 8.6-12.9 8.7-12.9 .1-.6-81.5-54.9-125.9-120.8-126.4-8.5-.1-8.5-12.9 0-12.9 73.7 .5 133 51.4 133.7 139.2zM374.9 329v.2c-10.8 19-31 40-51.8 33.3l-.2-.3c-21.1-5.9-70.8-31.5-102.2-56.5-16.2-12.8-31-27.9-42.4-42.4-10.3-12.9-20.7-28.2-30.8-46.6-21.3-38.5-26-55.7-26-55.7-6.7-20.8 14.2-41 33.3-51.8h.2c9.2-4.8 18-3.2 23.9 3.9 0 0 12.4 14.8 17.7 22.1 5 6.8 11.7 17.7 15.2 23.8 6.1 10.9 2.3 22-3.7 26.6l-12 9.6c-6.1 4.9-5.3 14-5.3 14s17.8 67.3 84.3 84.3c0 0 9.1 .8 14-5.3l9.6-12c4.6-6 15.7-9.8 26.6-3.7 14.7 8.3 33.4 21.2 45.8 32.9 7 5.7 8.6 14.4 3.8 23.6z" />
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 Powered by <b>ISolutions</b>
          </p>
        </div>
      </div>
    </div>
  );
};
