import React, { useEffect } from 'react';
import { FREQUENTLY_QUESTIONS } from '../constants';
import SEO from '../components/seo';

export const FAQ = () => {
  const renderFAQ = () =>
    FREQUENTLY_QUESTIONS.QUESTIONS.map((question) => (
      <div key={question.TITLE}>
        <h3>{question.TITLE}</h3>
        <p dangerouslySetInnerHTML={{ __html: question.TEXT }} />
      </div>
    ));

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <SEO
        title="FAQ Page"
        description="You can see all frequently asked questions for PremiumAutoImport"
      />
      <div id="faq" className="text-left">
        <div className="container">
          <div className="section-title">
            <h2 className="faq-title">{FREQUENTLY_QUESTIONS.TITLE}</h2>
          </div>
          {renderFAQ()}
        </div>
      </div>
    </>
  );
};
