import React from 'react';
import { ABOUT } from '../constants';

export const About = () => {
  const renderCards = () =>
    ABOUT.CARDS.map((card) => (
      <div key={card.TITLE} className="col-xs-12 col-md-6 col-lg-4">
        <i className={card.ICON}></i>
        <div className="about-desc">
          <h3>{card.TITLE}</h3>
          <p>{card.TEXT}</p>
        </div>
      </div>
    ));

  return (
    <div id="about" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{ABOUT.TITLE}</h2>
          <p>{ABOUT.DESCRIPTION}</p>
        </div>
        <div className="flex">{renderCards()}</div>
      </div>
    </div>
  );
};
