import { About } from '../components/about';
import { Buy } from '../components/buy';
import { Header } from '../components/header';
import { Partners } from '../components/partners';
import SEO from '../components/seo';
import { Testimonials } from '../components/testimonials';

const Home = () => (
  <>
    <SEO title="Home Page" description="You can see all information about PremiumAutoImport" />
    <Header />
    <Buy />
    <About />
    <Partners />
    <Testimonials />
  </>
);

export default Home;
