import { Outlet } from 'react-router-dom';
import { Contact } from '../components/contact';
import { Navigation } from '../components/navigation';

const MainLayout = () => (
  <>
    <Navigation />
    <Outlet />
    <Contact />
  </>
);

export default MainLayout;
