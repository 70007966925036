import React from 'react';
import { TESTIMONIALS } from '../constants';
import Slider from 'react-slick';

export const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: false
  };

  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>{TESTIMONIALS.TITLE}</h2>
        </div>
        <div className="row">
          <Slider {...settings}>
            {TESTIMONIALS.COLLECTION.map((comment) => (
              <div key={comment.NAME} className="col-md-12">
                <div className="testimonial">
                  <div className="testimonial-content">
                    <div className="testimonial-quote">
                      <i className="fa fa-quote-left" aria-hidden="true"></i>
                      <p>{`  ${comment.TEXT}  `}</p>
                      <i className="fa fa-quote-right" aria-hidden="true"></i>
                    </div>
                    <div className="testimonial-meta">{comment.NAME} </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};
