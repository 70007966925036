import React from 'react';
import { HEADER } from '../constants';
import { HashLink } from 'react-router-hash-link';

export const Header = () => (
  <header id="header">
    <div className="intro">
      <div className="overlay">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 intro-text mx-auto">
              <h1>{HEADER.TITLE}</h1>
              <h1>{HEADER.SUBTITLE}</h1>
              <HashLink to="/#buy" className="btn btn-custom btn-lg page-scroll">
                {HEADER.LEARN_MORE}
              </HashLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
);
