const NAVIGATION = {
  LOGO: 'img/logo-transparent.svg',
  ALT: 'PremiumAuto Import',
  ITEMS: [
    {
      HREF: '/#buy',
      LABEL: 'Покупка'
    },
    {
      HREF: '/#about',
      LABEL: 'За нас'
    },
    {
      HREF: '/#partners',
      LABEL: 'Партньори'
    },
    {
      HREF: '/#testimonials',
      LABEL: 'Отзиви'
    },
    {
      HREF: '/faq',
      LABEL: 'ЧЗВ'
    },
    { HREF: '/services', LABEL: 'Услуги' },
    {
      HREF: '/#contact',
      LABEL: 'Контакти'
    }
  ]
};

const HEADER = {
  TITLE: 'North america',
  SUBTITLE: 'Luxury cars',
  LEARN_MORE: 'Научете повече'
};

const BUY = {
  TITLE: 'Покупка на автомобил',
  CARDS: [
    {
      ICON: 'fa fa-solid fa-car',
      TITLE: 'Определяне на бюджет',
      TEXT: '1.  Първата и най-важна стъпка преди избора на мечтания за Вас автомобил е да определим ценовата рамка, в която ще се вместим.'
    },
    {
      ICON: 'fa fa-search',
      TITLE: 'Избор',
      TEXT: '2. Екипът на PremiumAuto Import ще Ви помогне в избора на най-подходящото превозно средство спрямо Вашите изисквания.'
    },
    {
      ICON: 'fa fa-calculator',
      TITLE: 'Калкулация ',
      TEXT: '3. Преди да извършим закупуването на избрания автомобил ще пресметнем точно всички разходи свързани с придобиването, ремонта (ако е необходим) и транспортирането до Вашето местоположение.'
    },
    {
      ICON: 'fa fa-group',
      TITLE: 'Търг',
      TEXT: '4. PremiumAuto Import е регистрирана фирма в САЩ и Канада и разполага с всички необходими лицензи за участие в онлайн търговете на IAAI и Copart.'
    },
    {
      ICON: 'fa fa-money',
      TITLE: 'Плащане',
      TEXT: '5. Плащането се извършва в рамките на 24-48 часа от закупуването на превозното чрез SWIFT банков превод по сметка на Copart или IAAI в САЩ/Канада, което гарантира сигурността на транзакцията.'
    },
    {
      ICON: 'fa fa-ship',
      TITLE: 'Доставка',
      TEXT: '6. Доставката отнема между 30-60 дни, което включва вътрешен транспорт от държавата износителка до съответното пристанище, от което превозното средство ще бъде натоварено за Европа, за да достигне крайната точка, избрана от Вас.'
    },
    {
      ICON: 'fa fa-book',
      TITLE: 'Документация',
      TEXT: '7. PremiumAuto Import поема пълната отговорност за уреждането на цялата необходима документация, свързана с митническо деклариране и освобождаване, транспортните компании и други институции.'
    },
    {
      ICON: 'fa fa-gavel',
      TITLE: 'Ремонт',
      TEXT: '8. Работим с  доверени сервизи и можем да Ви насочим при избора на такъв, ако автомобила изисква ремонт. Също така съдействие при преминаване на технотест и кодиране на мигачите по Европейски стандарт.'
    }
  ]
};

const ABOUT = {
  TITLE: 'За нас',
  DESCRIPTION: 'Защо да изберете PREMIUM AUTO IMPORT?',
  CARDS: [
    {
      ICON: 'fa fa-star',
      TITLE: '1. Гарантирано качество',
      TEXT: 'Преди да закупим желания от Вас автомобил, ние проверяваме пълната му история с помощта на различни инструменти '
    },
    {
      ICON: 'fa fa-money',
      TITLE: '2. Ще спестите пари',
      TEXT: 'С нашата помощ ще спестите от 30% от пазарната стойнсот на даденото превеозно средство.'
    },
    {
      ICON: 'fa fa-ship',
      TITLE: '3. Експресна доставка',
      TEXT: 'Ние работим с най-бързите транспортни фирми по море и суша.'
    },
    {
      ICON: 'fa fa-laptop',
      TITLE: '4. Поддръжка 24/7',
      TEXT: 'Екипът ни е на Ваше разположение по всяко време.'
    },
    {
      ICON: 'fa fa-search-plus',
      TITLE: '5. Огромен избор на всякакви превозни средства',
      TEXT: 'Около 600 000 автомобила са на търг едновременно. Наша задача е да изберем най-подходящия за Вас.'
    },
    {
      ICON: 'fa fa-pie-chart',
      TITLE: '6. Изгодни условия',
      TEXT: 'PremiumAuto Import предоставя на клиентите си най-ниски тарифи за комисионна, транспорт и доставка на МПС.'
    }
  ]
};

const PARTNERS = {
  TITLE: 'Партньори',
  OPTIONS: [
    { SRC: 'img/partners/copart.png', ALT: 'Copart' },
    {
      SRC: 'img/partners/iaai.png',
      ALT: 'IAAI'
    },
    {
      SRC: 'img/partners/logo-dekra.jpeg',
      ALT: 'DEKRA'
    },
    {
      SRC: 'img/partners/logo-viktoriatrans.png',
      ALT: 'VIKTORIA TRANS'
    }
  ]
};

const FREQUENTLY_QUESTIONS = {
  TITLE: 'Често задавани въпроси',
  QUESTIONS: [
    {
      TITLE:
        '1. С какво са по-добри автомобилите от САЩ и Канада? Какви са плюсовете пред тези от Европа ?',
      TEXT: 'Чували сме най-различни мнения по повод това откъде е по-удачно да бъде закупен автомобил. С оглед на това , че немските магистрали нямат ограничение на скоростта и автомобилите биват карани ежедневно с високи скорости , би следвало да заключим , че тяхното общо състояние е по-лошо , в Италия пък има тенденция масово колите да не се поддържат достатъчно , а тези , които са в добро състояние държат доста висока цена на пазара. Тоест страни като Италия и Германия предлагат коли с висока цена , която не отговаря на качеството , което получавате. В САЩ и Канада автомобилите биват поддържани най-често в оторизирани сервизи, карат се в ограничение , което е 100км/ч и километрите им биват значително по-малко в сравнение с европейските такива. Имайки предвид горепосочените фактори , смятаме , че отговорихме на най-често задаваният въпрос , а именно „Защо колите от САЩ и Канада са по-добра опция?“. Закупувайки автомобил през океана получавате гарантирано по-добро качество , спестявайки висок процент от пазарната му цена на територията на Европа.  '
    },
    {
      TITLE: '2. Как мога да внеса сам кола от САЩ или Канада ?',
      TEXT: `
        На първо място трябва да имате дилърски лиценз и лиценз за експорт, за да можете да закупите и изнесете автомобил. Той се дава само на Американски и Канадски дилъри на автомобили. 
        Необходимо е да организирате транспорта на превозното средство в контейнер, но за да получите цената, която ние можем да осигурим се нуждаете от закупуването на поне три автомобила. 
        Заплащате допълнителни такси, ако се забавите с плащането или транспорта на автомобила, паркинг за всеки изминал ден, транзакционни такси, неустойки и др. 
        Необходимо е сами да намерите лице в САЩ или Канада, което да провери автомобила на място преди да се натовари, както и дали е добре укрепен, което е от изключителна важност , тъй като има вероятност да възникнат нови повреди по автомобила при превозването му.  
        Също така е необходимо самостоятелно да организирате разтоварването на съответното пристанище, на което пристига превозното средство, както и процеса по обмитяване. 
        Последно, което трябва да направите е да организирате автовоз или платформа, за да докарате автомобила до желаната от Вас точка в България. `
    },
    {
      TITLE: '3. Кои превозни средства могат да се играят на търг ?',
      TEXT: 'Ние от фирма PremiumAuto Import сме официален дилър на превозни средства от САЩ и Канада и имаме право да наддаваме за всяко едно от тях. Съветваме ви да избягвате обявите с етикет Non-Branded. Това са автомобили, които имат право да се регистрират отново в САЩ и Канада и попадат в полезрението на местни дилъри на автомобили, което увеличава тяхната пазарна стойност. Мит е, че тези автомобили са в по-добро състояние от други подобни. В повечето случаи това са автомобили, за които е преценено, че е по изгодно да се ремонтират, отколкото да се продадат, заради по-леките им щети. '
    },
    {
      TITLE:
        '4. Колко време отнема процесът от закупуване до докарване на превозното средство от САЩ/Канада до България ?',
      TEXT: `
        Периодът за доставка не е фиксиран и зависи от това от коя страна е закупен автомобилът, къде се намира в страната износител и в какво състояние е. 
        Ориентировъчен срок за доставка може да дадем след като уточним откъде и какво превозно средство ще бъде закупено. 
        Приблизителния срок за доставка до България е между 30-60 календарни дни.`
    },
    {
      TITLE:
        '5. Има ли гаранция, че превозното средство, което закупувам ще дойде във вид, в който е описан в съответния търг ?',
      TEXT: `
        PremiumAuto Import работи с най-големите търгове и всички коли са в техническото и визуално състояние, в което са описани. Също така дали пали и се движи. 
        Някои от търговете, с които работим са : iaai.com , iaai.ca , copart.com , copart.ca `
    },
    {
      TITLE: '6. Какво се случва с автомобила ми след като го закупя ?',
      TEXT: `Както споменахме ние сме официален дилър на коли от САЩ и Канада, а не просто посредник. На ваше разположение са нашите складове в САЩ и Канада, където превозното Ви средство се съхранява преди да се натовари от транспортната фирма. 
        За Ваша сигурност и коректност сме осигурили личен персонал без да разчитаме на външни фирми, които товарят превозните средства. `
    },
    {
      TITLE:
        '7. Какви документи са необходими при внос на автомобили от страни извън ЕС, които трябва да бъдат предоставени на митницата ?',
      TEXT: `
        При внос на автомобил от страна извън Европейския съюз, независимо на коя Европейска митница, се подава митническа декларация. Към нея трябва да бъдат приложени следните документи: 
        Договор за покупко-продажба; 
        Транспортни документи – в случая автомобилът не е превозван на собствен ход, а на автовоз и в последствие в контейнер, затова за превоза също се заплащат такси и застраховки, които се прилагат и в последствие влизат в митническата стойност, върху която се начислява мито; 
        Договор за покупко-продажба или фактура; 
        Митническата декларация за допускане за свободно обращение и крайна употреба на автомобил, закупен от трета страна. Този документ се придружава и с талона за регистрация на автомобила от съответната държава, от която е закупен. Това се отнася само за автомобилите втора употреба. `
    },
    {
      TITLE:
        '8. Какви са митническите такси при пристигане на автомобила в Европа и кога трябва да се заплатят ?',
      TEXT: `
      Дължимите мита се определят в зависимост от тарифното класиране на даден артикул в Комбинираната номенклатура (КН) на ЕС и съгласно таблицата със ставките на митата, включена в Част втора на КН. 
      КН може да бъде консултирана на страницата за достъп до правото на ЕС, поддържана от Европейската комисия на адрес: <a target="_blank" rel="noreferrer" href="http://eur-lex.europa.eu">Кликни тук!</a>
      За нови и употребявани автомобили от позиция 8703 на КН ставката на митото от трети страни е 10 %. 
      За нови и употребявани автомобили от позиция 8704 на КН ставката на митото от трети страни зависи от максималното общо тегло на автомобила и от работния обем на двигателя – 10 % или 22 %.
      Справка може да бъде направена чрез базата данни ТАРИК (TARIC – Tarif Intégré de la Communauté) на Европейския съюз на адрес: 
      <a target="_blank" rel="noreferrer" href="http://ec.europa.eu/taxation_customs/dds2/taric/taric_consultation.jsp">Кликни тук!</a>
      За фирми регистрирани по ДДС ДДС се плаща чак при продажбата на автомобила на 3-то лице. Ако автомобилът остане за ползване на фирмата, ДДС не се дължи до неговата евентуална продажба в бъдеще. 
      Разтоварване межу €350 – €400 Всички митнически такси са дължими при разтоварването на автомобила на желаното от вас пристанище.
    `
    },
    {
      TITLE:
        '9. ма ли възможност при внос на автомобил от САЩ или Канада автомобилът да се освободи от мита и ДДС, ако е възможно, какви документи трябва да се представят ?',
      TEXT: `
        България, като държава членка на ЕС, прилага споразумения за свободна търговия (ССТ) с над 50 държави. Съгласно тези споразумения съществуват тарифни преференции при внос на стоки с произход от другата страна по ССТ. Списъкът на преференциалните търговски договорености на Европейския съюз е публикуван на интернет страницата на Агенция „Митници“ www.customs.bg , в секция „Произход на стоките“. 
        Тарифните преференции се предоставят при представяне на доказателство за произход, което може да бъде сертификат за движение EUR.1 или сертификат за движение EUR-MED, или декларация за произход, или изявление за произход. Справка по отношение на предвидените тарифни преференции при внос на автомобили от държавите включени в списъка на преференциалните търговски договорености на Европейския съюз, може да бъде направена чрез базата данни ТАРИК на адрес: <a target="_blank" rel="noreferrer" href="http://ec.europa.eu/taxation_customs/dds2/taric/taric_consultation.jsp">Кликни тук!</a> 
        По отношение на автомобилите, които са включени в Митническия съюз ЕС-Турция, доказателството им за свободно обращение и допускането им в ЕС от Турция без мито се извършва при представяне на сертификат за движение A.TR.По отношение на ДДС, дори когато не се начислява мито поради преференция, ДДС остава дължим. `
    },
    {
      TITLE: '10. Каква е цената за транспорта ?',
      TEXT: 'След като Ви помогнем за избора на автомобил , предстои да направим калкулация на сумата за транспорт. В зависимост от това къде се намира, вътрешния транспорт варира между $150 и $1050$. Средната цена за транспорта от САЩ/Канада до Холандия/Германия е около $1100. '
    },
    {
      TITLE: '11. Как да избера пристанище, от което да бъде изпратено превозното средство ?',
      TEXT: 'PremiumAuto Import има за задача да Ви предостави най-добрият и най-изгодният транспортен маршрут, след като сме избрали превозното средство.  '
    },
    {
      TITLE: '12. Има ли международна гаранция моят автомобил от САЩ или Канада ?',
      TEXT: 'Повечето марки предлагат международна гаранция, но ако автомобилът към който сте се насочили е още в гаранция, трябва да се консултирате допълнително с представителството на съответната марка. Някои марки в България са дилъри, а не официални представители!'
    },
    {
      TITLE: '13. Как се определя стойността на превозното средство, което искам да закупя ?',
      TEXT: `На първо място е необходимо да се определи бюджет, чийто рамки да бъдат спазвани при търсенето на автомобил. Една част от разходите е цената, на която ще бъде закупено превозното средство. Освен стойността на автомобила се заплаща комисионна на продавача (търга или дилъра ), транспорт, мито, ДДС, такси за натоварване и разтоварване от контейнера, нашата комисионна и др. такси. Няма конкретна формула, по която да се изчисли предварително колко ще струва доставката на дадено превозно средство, докато не изберете конкретно такова.  
        PremiumAuto Import е винаги готов да даде пълна и точна информация за всички разходи на своите клиенти. `
    },
    {
      TITLE: '14. Кой е отговорен за превозното средство при транспорта му по море и суша ?',
      TEXT: `
        Морските товарни компании, както и сухопътните такива, които превозват контейнерите носят ограничена отговорност за загуби. Поради тази причина ние Ви съветваме да застраховате транспортирането на закупения от Вас автомобил, за да избегнете излишни проблеми. За целта се изисква предварителна заявка от Вас, за да бъде изчислена допълнителната сума, която трябва да заплатите преди организирането на транспорта. 
        PremiumAuto Import  НЕ НОСИ отговорност за автомобилите по време на транспортиране. `
    },
    {
      TITLE: '15. Намерих автомобил сам, чрез интернет. Мога ли да разчитам на вашата помощ ?',
      TEXT: 'Разбира се, ние ще се радваме да Ви помогнем за договарянето на най-добри условия и цена за Вас '
    },
    {
      TITLE:
        '16. Има ли вероятност състоянието на автомобила да не съответства на това в оценката от търга ?',
      TEXT: 'Възможността да се случи е много рядка. Хората, които работят на търговете могат да пропуснат някои дефекти и са обект на човешки грешки. Не всички работници са професионалисти и ние не носим отговорност за това. За да се уверите максимално в състоянието на автомобила, PremiumAuto Import ще Ви изпрати снимки, направени от наш представител, които да Ви ориентират. '
    },
    {
      TITLE: '17. Възможно ли е да платя превозното средство след доставката ?',
      TEXT: 'PremiumAuto Import изисква пълното плащане преди доставка на превозното средство, защото е необходимо да го закупим за Вас от продавача, включително и да платим предварително транспортните разходи. Поради тази причина, транспортирането на автомобила Ви ще бъде възможен след като бъде извършено пълното плащане.'
    },
    {
      TITLE: '18. .Какви са санкциите, ако не получим парите навреме ?',
      TEXT: 'Вие имате 24-48 часа след покупката на превозното средство, за да направите окончателното плащане, посредством банков превод. Ако не получим плащането от Вас на третия ден след покупката, ще Ви таксуваме $150 CAD на ден, като такса за закъснение и ще бъдете помолени да изпратите копие от извлечение на плащането Ви заедно с информация за контакт на изпращащата банка. Ако не получим плащане или копие от извлечението на плащането от Вас в рамките на 5 дни, ние ще откажем или освободим превозното средство. Тогава следва да бъдете таксувани минимум $1000 CAD или 10% от цената на превозното средство, като такса за неговото освобождаване. '
    },
    {
      TITLE: '19. Какъв е минималния депозит, който се изисква ?',
      TEXT: 'Депозитът, който ние изискваме е минимум 2000лева или 15% от стойността на автомобила.'
    },
    {
      TITLE:
        '20. Ще загубя ли депозита си, ако не спечеля превозното средство при покупка на търг ?',
      TEXT: 'Няма да загубите депозита си. Ако не спечелите превозното средство, депозитът остава в сметката Ви за следващи покупки или ще Ви бъде възстановен.'
    },
    {
      TITLE: '21. Ще върнете ли моят депозит, ако го поискам ?',
      TEXT: 'ДА! В рамките на 24 часа ще Ви бъде върнат всеки депозит, който не е използван, или не е в процес на наддаване.'
    }
  ]
};

const SERVICES = {
  TITLE: 'Услуги',
  BUY: [
    {
      TITLE:
        '1. Достъп до всички търгове в САЩ и Канада за коли, лодки, каравани, мотори, моторни шейни, АТВ-та и други.',
      LINKS: [
        'https://ca.iaai.com',
        'https://www.iaai.com',
        'https://www.copart.ca',
        'https://www.copart.com',
        'https://lkqcanada.ca/',
        'https://sgi.sk.ca'
      ]
    },
    {
      TITLE:
        '2. Закупуване на коли, лодки, каравани, моторни шейни и АТВ от сайтове за обяви на автодилъри в Канада.',
      LINKS: [
        'https://www.autotrader.ca',
        'https://www.cars.ca',
        'https://www.kijiji.ca',
        'https://www.cargurus.ca'
      ]
    }
  ],
  OPTIONS: [
    {
      TITLE: '3. Транспорт от САЩ и Канада до България',
      TEXT: 'След като сме спечелили избраният от вас автомобил нашият екип изготвя оферта за транспорт от точката в която се намира до крайната точка в България. Приблизителната цена за от САЩ/Канада до Ротердам/Бремерхафен е $1100, а от Ротердам/Бремерхафен до България, около 1500 лева.'
    },
    {
      TITLE: '4. Обмитяване на МПС',
      TEXT: 'PremiumAuto Import е в тясно сътрудничество с най-добрите митнически агенции, чрез което осигуряваме професионална и сигурна обработка на цялата митническа процедура.'
    },
    {
      TITLE: '5. Съдействие за промяна на мигачите от американски към европейски стандарт',
      TEXT: 'Ние можем да съдействаме за подмяна на стоповете с европейски такива или за тяхното препрограмиране, (ако са диодни) за да светят в жълто.'
    },
    {
      TITLE: '6. Технотест',
      TEXT: 'Извършваме задължителния технотест за автомобили внесени извън ЕС в лицензирани компании за съответната дейност. '
    }
  ]
};

const TESTIMONIALS = {
  TITLE: 'Отзиви',
  COLLECTION: [
    {
      NAME: 'Николай Павлов',
      TEXT: 'Благодарен съм на екипа на PremiumAuto Import за тяхното невероятно обслужване и знания в автомобилната индустрия. Коректно отношение от избирането(и намирането) на точната конфигурация на искания от мен автомобил, напътствия и обратна връзка през целия процес до самото му пристигане в София. Цените им са разумни, сроковете спазени. При въпрос за това какви опции имам за резервни части при необходимост също ми бяха предложени варианти. Следващия автомобил ще е през тях със сигурност! Препоръчвам!'
    },
    {
      NAME: 'Андрей Манолов',
      TEXT: 'Изборът на PremiumAuto Import беше най-доброто решение, което взех за закупуване на автомобил. Техният специализиран екип от експерти предостави ценна помощ на всеки етап, включително препоръчване на надеждни сервизи и помощ при процеса на регистрация в България. Цените, които предлагат, са невероятно разумни, имайки предвид прецизната организация и изключителното обслужване, което предоставят. С увереност препоръчвам услугите им на всеки, който търси безпроблемно и ефективно изживяване при закупуване на автомобил.'
    },
    {
      NAME: 'Илия Димитров',
      TEXT: 'Горещо препоръчвам да се възползвате от услугите на PremiumAuto Import. Това са изключителни хора, знаещи как да обърнат внимание на клиента (нещо, което в България е отдавна забравено).'
    },
    {
      NAME: 'Крум Иванов',
      TEXT: 'Препоръчвам с две ръце PremiumAuto Import, ако сте се спрели да си закупите автомобил от Америка или Канада ! Винаги отзивчиви и изчерпателни за всяка една информация от ден първи. Определете си бюджет и действайте смело!'
    },
    {
      NAME: 'Пламен Пенев',
      TEXT: 'Попаднахме на контактите на PremiumAuto Import съвсем случайно в интернет. Чухме се с управителя им Христо и си уговорихме среща в офиса му. Подробно ни бяха обяснени всички стъпки и детайли по процеса на закупуване на автомобил от Северна Америка. Още на следващия ден бяхме собственици на новият ни семеен автомобил. Всички процедури по транспортирането, регистрацията и т.н минаха безупречно. Христо е изключителен професионалист, коректен, внимателен и отговорен човек, на когото може да се разчита. Препоръчал съм и продължавам да препоръчвам PremiumAuto Import на всички близки и приятели. Още веднъж огромни благодарности към целия екип на PremiumAuto Import ! '
    },
    {
      NAME: 'Кристина Борисова',
      TEXT: 'Много добра услуга, предлагана от кадърни хора, които си разбират от работата. Ще получите съдействие за един етап от закупуването на автомобила до препоръка на добри сервизи и регистрация в България. На всеки един въпрос ще ви бъде отговорено и може да очаквате съдействие. Силно препоръчвам, не се чудете !'
    }
  ]
};

const CONTACT = {
  TITLE: 'Свържете се с нас',
  SUBTITLE: 'Контакти',
  DESCRIPTION: 'При възникнални въпроси, ние сме 24/7 на разположение!',
  ADDRESS: {
    LABEL: 'Адрес',
    VALUE: 'Гр.София, Ж.к. Дианабад, улица 132, номер 11  ',
    LOCATION:
      'https://www.google.com/maps/dir/42.6409984,23.3373696/NPZ+Dianabad,+ul.+%22132-ra%22+11,+1113+Sofia/@42.6539555,23.3210331,14z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x40aa8434beec623f:0x34d0a498bd5406b6!2m2!1d23.3486774!2d42.6660227?entry=ttu'
  },
  PHONE: { LABEL: 'Телефон', VALUE_1: '0882712682', VALUE_2: '0886899074' },
  EMAIL: { LABEL: 'Email', VALUE: 'Premiumautoimport12@gmail.com' },
  SOCIAL_MEDIA: [
    {
      TITLE: 'facebook',
      HREF: 'https://www.facebook.com/profile.php?id=61558826360241'
    },
    {
      TITLE: 'instagram',
      HREF: 'https://www.instagram.com/_premiumautoimport_/?igsh=MXhiM3lrYTVyeG04bg%3D%3D'
    }
  ]
};

export {
  NAVIGATION,
  HEADER,
  BUY,
  ABOUT,
  PARTNERS,
  FREQUENTLY_QUESTIONS,
  SERVICES,
  TESTIMONIALS,
  CONTACT
};
