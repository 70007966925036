import React from 'react';
import SmoothScroll from 'smooth-scroll';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainLayout from './layouts/main-layout';
import Home from './pages/home';
import { FAQ } from './pages/faq';
import { Services } from './pages/services';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true
});

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Home />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/services" element={<Services />} />
        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  </Router>
);

export default App;
