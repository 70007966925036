import { PARTNERS } from '../constants';
import React from 'react';

export const Partners = () => {
  const renderPartners = () =>
    PARTNERS.OPTIONS.map((partner) => (
      <div key={partner.ALT} className="col-sm-12 col-md-12 col-lg-6">
        <img src={partner.SRC} className="w-100" alt={partner.ALT} />
      </div>
    ));

  return (
    <div id="partners" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{PARTNERS.TITLE}</h2>
        </div>
        <div className="partners-items row">{renderPartners()}</div>
      </div>
    </div>
  );
};
